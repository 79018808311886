exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-contact-mdx": () => import("./../../../src/pages/contact.mdx" /* webpackChunkName: "component---src-pages-contact-mdx" */),
  "component---src-pages-contact-thank-you-mdx": () => import("./../../../src/pages/contact/thank-you.mdx" /* webpackChunkName: "component---src-pages-contact-thank-you-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-portfolio-european-bee-eater-mdx": () => import("./../../../src/pages/portfolio/european-bee-eater.mdx" /* webpackChunkName: "component---src-pages-portfolio-european-bee-eater-mdx" */),
  "component---src-pages-portfolio-exhibition-mdx": () => import("./../../../src/pages/portfolio/exhibition.mdx" /* webpackChunkName: "component---src-pages-portfolio-exhibition-mdx" */),
  "component---src-pages-portfolio-giraffe-about-town-mdx": () => import("./../../../src/pages/portfolio/giraffe-about-town.mdx" /* webpackChunkName: "component---src-pages-portfolio-giraffe-about-town-mdx" */),
  "component---src-pages-portfolio-golden-snub-nosed-mdx": () => import("./../../../src/pages/portfolio/golden-snub-nosed.mdx" /* webpackChunkName: "component---src-pages-portfolio-golden-snub-nosed-mdx" */),
  "component---src-pages-portfolio-it-happened-here-mdx": () => import("./../../../src/pages/portfolio/it-happened-here.mdx" /* webpackChunkName: "component---src-pages-portfolio-it-happened-here-mdx" */),
  "component---src-pages-portfolio-mdx": () => import("./../../../src/pages/portfolio.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx" */),
  "component---src-pages-portfolio-naturalis-mdx": () => import("./../../../src/pages/portfolio/naturalis.mdx" /* webpackChunkName: "component---src-pages-portfolio-naturalis-mdx" */),
  "component---src-pages-portfolio-novels-reimagined-mdx": () => import("./../../../src/pages/portfolio/novels-reimagined.mdx" /* webpackChunkName: "component---src-pages-portfolio-novels-reimagined-mdx" */),
  "component---src-pages-portfolio-prop-box-mdx": () => import("./../../../src/pages/portfolio/prop-box.mdx" /* webpackChunkName: "component---src-pages-portfolio-prop-box-mdx" */),
  "component---src-pages-portfolio-were-not-scavengers-mdx": () => import("./../../../src/pages/portfolio/were-not-scavengers.mdx" /* webpackChunkName: "component---src-pages-portfolio-were-not-scavengers-mdx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */)
}

